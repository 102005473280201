import { Mixins, Component } from "vue-property-decorator";
import ModelMixin from "@/mixins/ModelMixin";
import { VueClass } from "vue-class-component/lib/declarations";
import { Brand, BrandCollection } from "@planetadeleste/vue-mc-shopaholic";

const TypedModelMixin = ModelMixin as unknown as VueClass<
  ModelMixin<Brand, BrandCollection>
>;

@Component
export default class BrandsMixin extends Mixins(TypedModelMixin) {
  obCollection = new BrandCollection();
  obModelClass = Brand;
  obCollectionClass = BrandCollection;
  sRouteName = "brands.list";

  created() {
    this.onCreated();
  }
}
